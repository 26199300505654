import React from "react"
import Icon from "../../utils/icons"
import {
  ProjectContainer,
  Border,
  Title,
  IconContainer,
  IconWrapper,
  Paragraph,
  LinkContainer,
  Link
} from "./projectCss"

export function Project({ title, description, liveURL, repoURL, skills }) {
  return (
    <Border>
      <ProjectContainer>
        <Title>{title}</Title>

        <IconContainer>
          {skills.map((skill, i) => {
            return (
              <IconWrapper key={i}>
                <Icon name={skill} />
              </IconWrapper>
            )
          })}
        </IconContainer>
        <Paragraph>{description}</Paragraph>
        <LinkContainer>
          <Link
            href={liveURL}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Click here to see the ${title} project live in a new tab`}
            title={`${title} Project Live`}
          >
            Live
          </Link>
          <Link
            href={repoURL}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Click here to see the ${title} project's repo in a new tab`}
            title={`${title} Project Repo`}
          >
            Code
          </Link>
        </LinkContainer>
      </ProjectContainer>
    </Border>
  )
}
