export const contactObj = [
  {
    name: "linkedin",
    link: "https://www.linkedin.com/in/florencia-calvo/"
  },
  {
    name: "twitter",
    link: "https://twitter.com/flora_codes"
  },
  {
    name: "github",
    link: "https://github.com/floraCodes"
  },
  {
    name: "mail",
    link: "mailto:contact@flo-ra.dev"
  }
]
