import styled from "@emotion/styled"

export const ContactContainer = styled.section`
  grid-area: contact;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const LinksContainer = styled.div`
  margin: 0.5rem;
  display: grid;
  gap: 3rem;
  justify-self: center;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);

  /* ok, you can laugh at this XD*/
  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 1290px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 860px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.9rem;
  }
`
export const LinkWrapper = styled.div`
  width: 5rem;
  @media (max-width: 500px) {
    width: 3rem;
  }
`
export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`
