import React from "react"
import { SectionSkills } from "../../sections/skills"
import { SectionProjects } from "../../sections/projects"
import { SectionAbout } from "../../sections/about"
import { SectionContact } from "../../sections/contact"
import { MainContainer } from "./mainCss"
import { Hero } from "../../hero"

export function Main() {
  return (
    <MainContainer>
      <Hero />
      <SectionProjects />
      <SectionContact />
      <SectionSkills />
      <SectionAbout />
    </MainContainer>
  )
}
