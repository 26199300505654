import styled from "@emotion/styled"

export const MainContainer = styled.main`
  margin: 3.5rem 0.5rem 0 0.3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "project  project   contact"
    "skills   about     about";
  gap: 0.3rem;

  @media (max-width: 1290px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "hero     hero"
      "project  project"
      "skills   contact"
      "about    about";
  }
  @media (max-width: 860px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "hero"
      "project"
      "about"
      "skills"
      "contact";
  }
`
