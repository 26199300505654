import styled from "@emotion/styled"

export const HeroContainer = styled.section`
  display: none;
  grid-area: hero;
  @media (max-width: 1290px) {
    display: flex;
    align-items: center;
    height: calc(100vh - 4rem);
  }
`
export const Paragraph = styled.p`
  font-size: 3rem;
  font-weight: bold;
  margin: 1rem 1rem 2rem 1rem;
`
