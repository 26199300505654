import styled from "@emotion/styled"

export const AboutContainer = styled.section`
  grid-area: about;
  display: flex;
  flex-direction: column;
`
export const AboutWrapper = styled.div`
  margin: 1rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2rem;
  @media (max-width: 860px) {
    display: flex;
    flex-direction: column;
  }
`
export const TextWrapper = styled.div`
  display: grid;
  gap: 0.8rem;
  font-size: 1.3rem;
`
export const Paragraph = styled.p`
  line-height: 1.5rem;
  margin-right: 1rem;
  margin-bottom: 0.7rem;

  @media (max-width: 860px) {
    text-align: justify;
    margin-right: 0;
  }
`
export const RedText = styled.span`
  color: #cf0707;
`
export const Img = styled.img`
  margin-bottom: 1rem;
  @media (max-width: 860px) {
    align-self: center;
  }
  @media (max-width: 500px) {
    width: 80%;
  }
`
