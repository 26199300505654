import React from "react"
import { contactObj } from "../../../utils/contact"
import Icon from "../../../utils/icons"
import { Title } from "../../title"
import {
  ContactContainer,
  LinksContainer,
  LinkWrapper,
  Container,
} from "./contactCss"

export function SectionContact() {
  return (
    <ContactContainer id="contact">
      <Title sectionName="contact" emoticonName="phone" />
      <Container>
        <LinksContainer>
          {contactObj.map((contact, i) => {
            const { name, link } = contact
            const ariaLabel =
              name === "mail"
                ? "Click here to send me an email"
                : `Click here to check my ${name} in a new tab`
            return (
              <LinkWrapper>
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={ariaLabel}
                  title={name}
                >
                  <Icon name={name} />
                </a>
              </LinkWrapper>
            )
          })}
        </LinksContainer>
      </Container>
    </ContactContainer>
  )
}
