import styled from "@emotion/styled"

export const SkillsContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: skills;
`

export const SkillsWrap = styled.div`
  display: grid;
  column-gap: 4rem;
  row-gap: 6rem;

  grid-template-columns: repeat(4, minmax(auto, 100px));
  place-items: center;
  align-self: center;
  margin: 1rem;
  @media (max-width: 860px) {
    row-gap: 4rem;
  }

  @media (max-width: 500px) {
    column-gap: 0.3rem;
    row-gap: 1rem;
  }
`

export const Skill = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 50%;
  height: 6rem;
  & p {
    text-align: center;
    font-size: 1rem;
  }
`

export const IconWrap = styled.div`
  width: 4rem;
  @media (max-width: 500px) {
    width: 3rem;
  }
`
