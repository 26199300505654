import React from "react"
import { projectsObj } from "../../../utils/projects"
import { Title } from "../../title"
import { Project } from "../../project"
import { ProjectsContainer, ProjectsWrapper, Link } from "./projectsCss"

export function SectionProjects() {
  return (
    <ProjectsContainer id="projects">
      <Title sectionName="projects" emoticonName="files" />

      <ProjectsWrapper>
        {projectsObj.map((project, i) => {
          const { title, description, skills, repoURL, liveURL } = project
          return (
            <Project
              key={i}
              title={title}
              description={description}
              repoURL={repoURL}
              liveURL={liveURL}
              skills={skills}
            />
          )
        })}
      </ProjectsWrapper>
      <Link
        href="https://github.com/floraCodes"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Click here to see more projects in a new tab"
        title="More Projects"
      >
        more projects
      </Link>
    </ProjectsContainer>
  )
}
