import React from "react"
import styled from "@emotion/styled"
import Emoticon from "../utils/emoticons"

const TitleContainer = styled.div`
  display: flex;
  margin: 0.5rem 1rem;

  @media (min-width: 861px) {
    align-self: flex-start;
  }
`
const EmoticonContainer = styled.div`
  width: 2rem;
`
const TitleWrapper = styled.h2`
  margin-left: 0.3rem;
`

export function Title({ sectionName, emoticonName }) {
  return (
    <TitleContainer>
      <EmoticonContainer aria-hidden="true">
        <Emoticon name={emoticonName} />
      </EmoticonContainer>
      <TitleWrapper>{sectionName}</TitleWrapper>
    </TitleContainer>
  )
}
