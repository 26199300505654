import React from "react"
import Icon from "../../../utils/icons"
import { skillsObj } from "../../../utils/skills"
import { Skill, IconWrap, SkillsWrap, SkillsContainer } from "./skillsCss"
import { Title } from "../../title"

export const SectionSkills = () => {
  return (
    <SkillsContainer id="skills">
      <Title sectionName="skills" emoticonName="hat" />
      <SkillsWrap>
        {skillsObj.map((skill, i) => (
          <Skill key={i}>
            <IconWrap>
              <Icon name={skill.name} />
            </IconWrap>
            <p style={{ textTransform: skill.textStyle }}>{skill.name}</p>
          </Skill>
        ))}
      </SkillsWrap>
    </SkillsContainer>
  )
}
