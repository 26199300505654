import styled from "@emotion/styled"

export const Border = styled.div`
  border: 3px solid black;
  width: 100%;
  min-width: 17rem;
  max-width: 25rem;
`

export const ProjectContainer = styled.article`
  display: grid;
  grid-template-rows: 0.3fr 0.3fr 7rem 1.5fr;
  gap: 0.3rem;
  align-items: start;
  min-height: 13rem;
  height: 100%;
  margin: 0.5rem;
`
export const IconWrapper = styled.div`
  width: 2rem;
  margin: 0.3rem 1rem 0.3rem 0;
`
export const IconContainer = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  height: 2rem;
  margin: 0.3rem 0;
`
export const Paragraph = styled.p`
  font-size: 1.3rem;
  align-self: flex-start;
`
export const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0.3rem;
`
export const LinkContainer = styled.div`
  margin: 0.7rem 0;
  justify-self: center;
  align-self: center;
`
export const Link = styled.a`
  border: 3px solid black;
  color: #fff;
  background: #000;
  text-decoration: none;
  font-weight: bold;
  padding: 0.1rem 1.3rem;
  font-size: 1.2rem;
  margin: 0 1rem;
`
