import React from "react"
import { HeroContainer, Paragraph } from "./heroCss"

export function Hero() {
  return (
    <HeroContainer>
      <Paragraph>Let's create awesome things together.-</Paragraph>
    </HeroContainer>
  )
}
