import React from "react"
import flora from "../../../images/Picture.png"
import { Title } from "../../title"
import {
  AboutContainer,
  Paragraph,
  RedText,
  TextWrapper,
  AboutWrapper,
  Img
} from "./aboutCss"

export function SectionAbout() {
  return (
    <AboutContainer id="about">
      <Title sectionName="about" emoticonName="ADN" />
      <AboutWrapper>
        <Img src={flora} alt="Flora's portrait in black and white" />
        <TextWrapper>
          <Paragraph>
            Hi there! My name is <RedText>Flora Calvo</RedText> and I’m a
            <RedText> Frontend Web Developer.</RedText>
            <br />
            I’m based in Sydney and I’m looking for a dev role.
          </Paragraph>
          <Paragraph>
            <RedText>Passion</RedText> about problem solving is what got me into
            code, being able to come up with creative solutions is what makes me
            stay.
          </Paragraph>
          <Paragraph>
            I enjoy learning as much as I enjoy teaching, I think both are
            equally fulfilling activities to me, and when I look for a job,
            continuous learning is a priority.
          </Paragraph>
          <Paragraph>
            I usually spend my days coding something cool or learning new
            things.
            <br />
            If I’m not coding, I’ll be at the gym, taking photos or walking the
            dog.
          </Paragraph>
        </TextWrapper>
      </AboutWrapper>
    </AboutContainer>
  )
}
