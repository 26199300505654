export const skillsObj = [
  {
    name: "html",
    textStyle: "uppercase"
  },
  {
    name: "css",
    textStyle: "uppercase"
  },
  {
    name: "js",
    textStyle: "uppercase"
  },
  {
    name: "ts",
    textStyle: "uppercase"
  },
  {
    name: "react",
    textStyle: "capitalize"
  },
  {
    name: "git",
    textStyle: "capitalize"
  },
  {
    name: "vsc",
    textStyle: "uppercase"
  },
  {
    name: "figma",
    textStyle: "capitalize"
  }
]
