import styled from "@emotion/styled"

export const ProjectsContainer = styled.section`
  grid-area: project;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ProjectsWrapper = styled.div`
  flex-grow: 1;
  margin: 0 0.3rem;
  grid-template-columns: repeat(3, minmax(17rem, 25rem));
  gap: 0.3rem;
  display: flex;

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
  }
`
export const Link = styled.a`
  color: #fff;
  background: #000;
  text-decoration: none;
  font-weight: bold;
  padding: 0.8rem 1.3rem;
  font-size: 1.2rem;
  margin: 0.8rem;
`
