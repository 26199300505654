import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Main } from "../components/layout/Main"
import "./normalize.css"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Main />
    </Layout>
  )
}

export default IndexPage
