export const projectsObj = [
  {
    id: "p-01",
    title: "Todo List",
    description:
      "The most original choice of project out there (I bet you never saw anything like this), check it out!",
    skills: ["css", "js", "react"],
    img: "todolist.png",
    repoURL: "https://github.com/floraCodes/first-react-todo",
    liveURL: "https://first-react-todo.now.sh/"
  },
  {
    id: "p-02",
    title: "My Notes App",
    description:
      "A simple but beautifully designed Google Keep Clone. Features x-mansory library for a cool layout.",
    skills: ["js", "react", "sc"],
    img: "url",
    repoURL: "https://github.com/floraCodes/my-notes-app",
    liveURL: "https://goofy-northcutt-d45208.netlify.app/"
  },
  {
    id: "p-03",
    title: "Kanban Board",
    description:
      "Because it seems I'm a bit obsessed with productivity. Features react-beautiful-dnd.",
    skills: ["js", "react", "sc"],
    img: "url",
    repoURL: "https://github.com/floraCodes/trello-clone",
    liveURL: "https://trello-clone-flax.vercel.app/"
  }
]
